<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.ban-announce.new-branch"></custom-page-title>

                <p>A pénzintézeti jelentés <b>Cégnyilvántartás</b> részével
                    lehet új pénzintézet adatait a cégbírósággal közölni vagy már meglévő adatokat
                    módosítani.<br>
                    Az <a
                        href="/ban/xmljelentes.exe" target="_blank">XMLjelentés</a> program segítségével a szükséges
                    adatokat a <b>Nézet</b> menü <b>Cégnyilvántartás</b> pontjára váltva,
                    a <b>Szerkesztés</b> menü <b>Új adatok</b> funkció segítségével töltheti
                    ki.<br>
                    A <b>Pénzintézet számjele</b> itt a pénzintézet által kiadott pénzforgalmi
                    jelzőszámok első 8 jegye. A <i>Kód</i> mező <i>&quot;Bejegyzés&quot;</i>-re legyen beállítva.</p>

                <p>Törekedjen arra, hogy a pénzintézet neve kifejezze, hogy a
                    pénzintézet melyik fiókjáról van szó. Mivel egyes pénzintézetek helytelen
                    adatokat küldenek, ezért ezen adatok feldolgozása nem automatikus.</p>

                <div>A helyes eljárás:
                    <ul>

                        <li>az új pénzintézet adatait küldje el először a
                            cégnyilvántartás részére,
                        </li>
                        <li>ezután küldje el az új pénzintézetre vonatkozó jelentést.</li>
                    </ul>
                    <p></p>
                </div>

                <p>Egy cégkivonaton pl. az alábbi szöveg jelenik meg:</p>

                <table border="0" cellpadding="0" cellspacing="0" style="font-style: italic">
                    <tr>
                        <td>
                            32.
                        </td>
                        <td>
                            A cég pénzforgalmi jelzőszáma
                        </td>
                    </tr>
                    <tr>
                        <td>
                            32/1.
                        </td>
                        <td>
                            14100000-11111111-01000001
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            A számla megnyitásának dátuma: 2002/10/16.
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            A pénzforgalmi jelzőszámot a cégjegyzékszámú
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            Magyarországi Volksbank Rt. Központi fiók (1088 Budapest,
                            Rákóczi. u. 8.) kezeli.
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            Hatályos: 2003/01/16 ...
                        </td>
                    </tr>
                </table>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpBanAnnounceNewBranch extends Vue {
}
</script>

<style scoped>

</style>